.gridWrapper {
  padding: 0 15px;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .gridWrapper {
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .gridWrapper:nth-child(2) {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1024px) {
  .gridWrapper:nth-child(2) {
    padding: 0 30px;
    max-width: 1440px;
    margin: 0 auto;
  }

  .gridWrapper:nth-child(1) {
    padding: 0;
  }
}